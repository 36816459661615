<template>
  <a-modal v-model="modalVisible" title="选择能耗表" :width="1200" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="能耗类型" prop="type">
          <a-select v-model="queryParams.type"  style="width: 140px">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="(item, index) in ecTypeList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

          <a-form-model-item label="能耗表编号" prop="code" >
            <a-input v-model="queryParams.code" style="width: 140px"></a-input>
          </a-form-model-item>
        <a-form-model-item label="仪表名称" prop="meter_name" style="width: 25%">
          <a-input v-model="queryParams.meter_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="能耗表名称" prop="ec_meter_name" style="width: 25%">
          <a-input v-model="queryParams.ec_meter_name"></a-input>
        </a-form-model-item>

        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
<!--        </div>-->
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2===0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getCache} from "U/index";
import ecCommont from "../mixins/ecMixin"
import {getAllEcRecordIdAndCodeListByCondition, getEcRecordListByCondition} from "A/energyconsumption";
export default {
  mixins: [pagination,ecCommont],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'radio'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    },
    formData:{
      default() {
        return [];
      }
    },
    showType: {
      default: 'add'
    },
  },
  data() {
    return {
      modalVisible: false,
      queryParams: {
        code: '',
        type: '',
        meter_name: '',
        ec_meter_name: '',

      },
      tableLoading: false,
      tableColumns: [
        {
          title: '能耗表编号',
          dataIndex: 'code',
          key: 'code',
          ellipsis: true,
        },
        {
          title: '能耗类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
        },
        {
          title: '级数',
          dataIndex: 'level',
          key: 'level',
          ellipsis: true,
        },
        {
          title: '仪表名称',
          dataIndex: 'meter_name',
          key: 'meter_name',
          ellipsis: true,
        },
        {
          title: '分组名',
          dataIndex: 'group_name',
          key: 'group_name',
          ellipsis: true,
        },
        {
          title: '表号',
          dataIndex: 'meter_code',
          key: 'meter_code',
          ellipsis: true,
        },
        {
          title: '能耗表名称(出厂名称)',
          dataIndex: 'ec_meter_name',
          key: 'ec_meter_name',
          ellipsis: true,
        },
        {
          title: '规格型号',
          dataIndex: 'model',
          key: 'model',
          ellipsis: true,
        },
      ],
      deviceCodeList:[],
      equipmentStatusList:[],
      tableData: [],
      selectedRows: [],
      selectedRowsEnsure: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.id);
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        let _arr = []
        //this.selectedRows = this.defaultSelected || [];
        this.selectedRows = (this.defaultSelected? _arr.concat(this.defaultSelected):[]) || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentStatusList = dictionary.equipmentStatusList
    }
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData()
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        not_all_data:'1'
      };
      getEcRecordListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType === 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.id) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.id!==record.id)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.id) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.id!==record.id)
        })
      }
    },
    confirm() {
      this.$emit('get-primary-selected-rows', this.selectedRows);
      this.modalVisible = false;
    },
  }
}
</script>