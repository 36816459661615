<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{ showType == 'detail' ? '关闭' : '取消' }}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">
          提交
        </a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules"
                    :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="userdep_id">
          <a-select :disabled="showType=='detail'" v-model="formData.userdep_id" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="能耗表编号" prop="code">
<!--          <a-select :disabled="showType=='detail'" v-model="formData.record_id" :filter-option="filterOption">-->
<!--            <a-select-option value="">请选择能耗表编号</a-select-option>-->
<!--            <a-select-option v-for="(item, index) in ecRecordAllIdAndCodeData" :key="index" :value="item.id">-->
<!--              {{ item.code }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
          <div style="display: flex;flex-direction: row;gap: 10px">
            <a-input disabled v-model.trim="formData.code"/>
            <a-button v-if="showType==='add' || showType==='edit'" type="primary" @click="selectAllocationRecord()">选择</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="图位号" prop="map_location_code">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.map_location_code"/>
        </a-form-model-item>
        <a-form-model-item label="能耗类型" prop="type">
          <a-input disabled v-model.trim="formData.type"/>
        </a-form-model-item>
        <a-form-model-item label="级数" prop="level">
          <a-input disabled v-model.trim="formData.level"/>
        </a-form-model-item>
        <a-form-model-item label="区域" prop="region">
          <a-select :disabled="showType=='detail'" v-model="formData.region">

            <a-select-option v-for="(item, index) in ecRegionList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="仪表名称" prop="meter_name">
          <a-input disabled v-model.trim="formData.meter_name"/>
        </a-form-model-item>
<!--        <a-form-model-item label="位置" prop="position">-->
<!--          <a-input :disabled="showType=='detail'" v-model.trim="formData.position"/>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="位置" prop="position" >
          <a-select v-model="formData.position">

            <a-select-option v-for="(item, index) in ecPositionAllList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="能耗类别" prop="category">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.category">

            <a-select-option v-for="(item, index) in ecCategoryAllList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计量范围" prop="measuring_range">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.measuring_range"/>
        </a-form-model-item>
        <a-form-model-item label="计量范围面积(m2)" prop="measuring_range_area">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.measuring_range_area" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="表号" prop="meter_code">
          <a-input disabled v-model.trim="formData.meter_code"/>
        </a-form-model-item>
        <a-form-model-item label="倍率" prop="magnification">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.magnification" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="能耗表状态" prop="meter_status" >
          <a-select :disabled="showType=='detail'" v-model="formData.meter_status">

            <a-select-option v-for="(item, index) in ecStatusList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否收费" prop="fee_enable" >
          <a-select :disabled="showType=='detail'" v-model="formData.fee_enable">

            <a-select-option v-for="(item, index) in ecFeeSelectList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <selectClassificationModal :visible.sync="selectVisible" :default-selected="selectedRecord" :formData="formData" :showType="showType" @get-primary-selected-rows="getPrimaryRecordList"></selectClassificationModal>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addEcAllocationRecord,
  editEcAllocationRecord, getAllEcRecordIdAndCodeListByCondition,
  getOneRecordById,
} from "A/energyconsumption";

import ecCommont from "../mixins/ecMixin"
import selectClassificationModal from "./selectClassificationModal";


export default {
  components: {selectClassificationModal},
  mixins: [areaselect, deptselect, ecCommont],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecRecordAllIdAndCodeData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '350px',
        overflowY: 'auto',
      },
      modalVisible: false,
      selectVisible:false,
      selectedRecord:null,
      formData: {
        monitorpointnum: '',
        userdep_id: '',
        record_id: '',
        type: '',
        level: '',
        meter_name: '',
        meter_code: '',
        category:'',
        measuring_range:'',
        measuring_range_area:"",
        region:'',
        position:'',
        meter_status: '',
        fee_enable: '',
        magnification:"",
        map_location_code:"",
        code:'',
      },
      //这里面的数据属于必填项
      formRules: {
        userdep_id: [{required: true, message: '请选择项目'}],
        record_id: [{required: true, message: '请选择能耗表编号'}],
        type: [{required: true, message: '请选择能耗类型'}],
        level: [{required: true, message: '请选择级数'}],
        meter_name: [{required: true, message: '请输入仪表名称'}],
        meter_code: [{required: true, message: '请输入表号'}],
        measuring_range: [{required: true, message: '请输入计量范围'}],
        measuring_range_area: [{required: true, message: '请输入计量范围面积'}],
        magnification: [{required: true, message: '请输入倍率'}],
        category: [{required: true, message: '请选择能耗类别'}],
        region: [{required: true, message: '请选择区域'}],
        position: [{required: true, message: '请选择位置'}],
        meter_status: [{required: true, message: '请选择能耗表状态'}],
        fee_enable: [{required: true, message: '请选择是否收费'}],
      },
      ecPositionAllList: [],
      monitorpointList: [],
      ecCategoryAllList:[],

      ecMeterFormAllList: [],
      ecGradeList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    'formData.userdep_id'(val) {
      //this.formData.monitorpointnum = ''
      for (let item of this.monitorpointList) {
        if (item.userdepid === val) {
          this.formData.monitorpointnum = item.monitorpointnum
        }
      }
    },
    'formData.region'(val){
      if (val && val.length) {
        this.ecCategoryAllList = this.ecCategoryList[val][this.formData.type]
        this.ecPositionAllList = this.ecPositionList[val]
      } else {
        this.ecCategoryAllList = []
        this.ecPositionAllList = []

      }
    },
    'formData.type'(val){
      if (val && val.length) {
        if(val!==this.detailData.type){
          this.formData.category=''
          this.ecCategoryAllList = []
          if(this.formData.region!==''){
            this.ecCategoryAllList = this.ecCategoryList[this.formData.region][val]
          }
        }
      } else {
        this.formData.category=''
      }
    },
    'formData.record_id'(val){
      if (val) {
        //获取一个record
        this.getOneRecord(val);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    getOneRecord(id){
      let params = {
        id:id
      };
      getOneRecordById(params).then(res => {
        if (res && res.returncode == '0') {
          console.log(res.item)
          if(res.item && res.item.length!==0){
              this.formData.type=res.item[0].type;
              this.formData.level=res.item[0].level;
              this.formData.meter_name=res.item[0].meter_name;
              this.formData.meter_code=res.item[0].meter_code;
              //this.formData.map_location_code=res.item[0].map_location_code;
          }
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.id) {
        if (this.showType == 'edit' || this.showType == 'detail') {
          this.formData.id = this.detailData.id
          this.formData.record_id = this.detailData.record_id
          this.formData.type = this.detailData.type
          this.formData.level = this.detailData.level
          this.formData.meter_name = this.detailData.meter_name
          this.formData.meter_code = this.detailData.meter_code

          this.formData.category = this.detailData.category
          this.formData.measuring_range = this.detailData.measuring_range
          this.formData.measuring_range_area = this.detailData.measuring_range_area
          this.formData.region = this.detailData.region
          this.formData.position = this.detailData.position
          this.formData.meter_status = this.detailData.meter_status
          this.formData.fee_enable = this.detailData.fee_enable
          this.formData.magnification = this.detailData.magnification

          this.formData.userdep_id = this.detailData.userdep_id
          this.formData.monitorpointnum = this.detailData.monitorpointnum
          this.formData.map_location_code = this.detailData.map_location_code
          this.formData.code = this.detailData.code

        } else {
          this.resetData()
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formData,
              //userdepsid:this.formData.userdep_id
            };
            if (this.showType == 'add') {
              this.showLoading();
              addEcAllocationRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if (this.showType == 'edit') {
              this.showLoading();
              editEcAllocationRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    selectAllocationRecord(){
      this.selectVisible=true
    },
    getPrimaryRecordList(rows){
      this.formData.code=rows[0].code;
      this.formData.type=rows[0].type;
      this.formData.level=rows[0].level;
      this.formData.meter_name=rows[0].meter_name;
      this.formData.meter_code=rows[0].meter_code;
      this.formData.record_id=rows[0].id;

      this.selectedRecord=rows
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>